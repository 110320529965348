export const urlHinoConnect = {
  mainHinoConnect: 'hino-connect',
  inquiryList: '/hino-connect/inquiry-list',
  inquiryForm: '/hino-connect/inquiry-form',
  inquiryDetails: '/hino-connect/inquiry',
  importFiles: '/hino-connect/import-files',
  fileViewer: '/hino-connect/file-viewer',
};

export const urlHinoSOS = {
  inquiryList: '/hino-sos/inquiry-list',
  inquiryForm: '/hino-sos/inquiry-form',
  inquiryDetails: '/hino-sos/inquiry',
  createSpecialClient: '/hino-sos/create-special-client',
  carDealerDetails: '/hino-sos/car-dealer-details',
  carDealerSearch: '/hino-sos/car-dealer-search',
  confirmedReport: '/hino-sos/confirmed_report',
  exportData: '/hino-sos/export-data',
  shiftTable: '/hino-sos/shift-table',
};

export const urlSetting = {
  settingInfo: '/setting-info',
  changeHitossUrl: 'api/changeHitossUrl',
  updateExpresswayUrl: 'api/expressway/update',
};

export const urlJustDoIt = {
  callList: '/just-do-it/call-list',
  followCall: '/just-do-it/follow-call',
  callApplication: '/just-do-it/call-application'
};

export const urlOutBoundCall = {
  promoteWarehouseCallList: '/outbound/promote-warehouse-call-list',
  promoteWarehouseCallDetail: '/outbound/detail-promote-warehouse-call-list',
  afterCallList: '/outbound/after-call-list',
  afterCallDetail: '/outbound/after-outgoing-call-external'
};
